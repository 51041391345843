/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from "react";
import './navbar.css'


const Navbar = () => {

  const [open, setOpen] = useState(false);

  return (
    <nav>
      <a href="/">
        Hakan.
      </a>
      <ul>
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#contact">Contact Me</a>
        </li>
      </ul>
      <div className="mobileMenu" onClick={()=> setOpen(!open)}>
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
      <ul onClick={()=> setOpen(false)} className="menu" style={{right: open ? "0px" : "-100vw"}}>
        <li className="menuItem">
          <a href="/products/design">About</a>
        </li>
        <li className="menuItem">
          <a href="/products/development">Portfolio</a>
        </li>
        <li className="menuItem">
          <a href="/products/production">Skills</a>
        </li>
        <li className="menuItem">
          <a href="#contact">Contact Me</a>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar