import React from "react";
import "./header.css";
import CTA from "./CTA";
import Socials from "./Socials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h2>Hello, I'm</h2>
        <h1>Hakan Özkan.</h1>
        <h3>I'm a Javascript developer.</h3>
        <p>
          I describe myself as a passionate developer who enjoys creating things
          that live on the internet. Currently, I'm focused on building
          beautiful and engaging digital experiences.
        </p>
        <CTA />
        <Socials />
      </div>

      {/* <div className="me">
          <img src={ME} alt="" />
        </div> */}
    </header>
  );
};

export default Header;
