import React from 'react'
import {FiLinkedin} from 'react-icons/fi'
import {FiGithub} from 'react-icons/fi'

const Socials = () => {
  return (
    <div className='header_socials'>
        <a href="https://github.com/hakanozkan1" target="_blank" rel="noreferrer"  ><FiGithub size={25}/></a>
        <a href="https://www.linkedin.com/in/hakanozkan1/" target="_blank" rel="noreferrer" ><FiLinkedin size={25}/></a>
    </div>
  )
}

export default Socials