import Img0 from '../../assets/Home.png'
import Img1 from '../../assets/blog.png'
import Img2 from '../../assets/ecommerce.png'
import Img3 from '../../assets/pinchat.png'
import Img4 from '../../assets/cryptocurrencies.png'


const data = [
    {
        id:0,
        image: Img0,
        title: 'English Mobile App',
        github: "https://github.com/hakanozkan1",
        demo: "https://play.google.com/store/apps/details?id=com.dialogapprn&pli=1" ,
        stack: ["React Native","OpenAI"]
    },
    {
        id:1,
        image: Img1,
        title: 'Blog App',
        github: "https://github.com/hakanozkan1/next_blog",
        demo: "https://hakandevblog.vercel.app/" ,
        stack: ["Next.js","GraphQL","Tailwind CSS"]
    },
    {
        id:2,
        image: Img2,
        title: 'E-Commerce App',
        github: "https://github.com/hakanozkan1/ecommerce",
        demo: "https://boutiqueplus.netlify.app/",
        stack: ["React.js","Node.js","Express","MongoDB"]
    },
    {
        id:3,
        image: Img3,
        title: 'Chat App',
        github: "https://github.com/hakanozkan1/pinchat-app",
        demo: "https://pinchatapp.netlify.app/" ,
        stack: ["React.js","Node.js","Express","MongoDB"]
    },
    {
        id:4,
        image: Img4,
        title: 'Cryptocurrency App',
        github: "https://github.com/hakanozkan1/cryptocurrencyList",
        demo: "https://cryptolistcurrency.netlify.app/",
        stack: ["React.js"] 
    }
]

export default data