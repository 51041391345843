import React from "react";
import "./about.css";
import ME from "../../assets/ME.jpg";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <p>
            I develop websites and mobile applications with <span>HTML, CSS and JavaScript. </span>
            I honed my skills at develop creative, responsive website layouts. Here are a few technologies I’ve been using with
            recently:
          </p>
          <div className="about__cards">
            <article className="about__card">
              <h5>React</h5>
            </article>
            <article className="about__card">
              <h5>React Native</h5>
            </article>
            <article className="about__card">
              <h5>Next</h5>
            </article>
            <article className="about__card">
              <h5>Node</h5>
            </article>
            <article className="about__card">
              <h5>Firebase</h5>
            </article>
            <article className="about__card">
              <h5>MongoDB</h5>
            </article>
            <article className="about__card">
              <h5>GraphQL</h5>
            </article>
            <article className="about__card">
              <h5>Bootstrap</h5>
            </article>
          </div>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
