/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import './portfolio.css'
import data from './data'
import {BsCollectionPlay} from 'react-icons/bs'



const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Personal Projects</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {data.map(item => (
          <article className='portfolio__item' key={item.id}>
          <div className='portfolio__item-image'>
            <img src={item.image} alt="" />
          </div>
          <h2>{item.title}</h2>
          <div className='portfolio__item-stack'>
            {item.stack.map(sta => (
              <p>{sta}</p>
            ))}
          </div>
          <div className="portfolio__item-cta">
            <a href={item.github} className='btn' target='_blank'>Github</a>
            <a href={item.demo} className='btn btn-primary btn-private' target='_blank'>
              Live
              <BsCollectionPlay size={20} style={{margin: "0 0 2px 6px"}} />
            </a>
          </div>
        </article>
        ))}
        
      </div>
    </section>
  )
}

export default Portfolio